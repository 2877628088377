import logo from "./logo.svg";
import "./App.css";
import { useEffect } from "react";
import axios from "axios";

function App() {
  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    const response = await axios.get(
      "https://northwind-worker.lab1.workers.dev/api/classes"
    );

    console.log(response);
  };

  const postData = async () => {
    try {
      await axios({
        method: "post",
        url: "https://northwind-worker.lab1.workers.dev/api/class",
        headers: {},
        data: {
          id: 999,
          name: "class 999",
          des: "description for 999",
        },
      });
    } catch (error) {}
    // try {
    //   const response = await axios.post(

    //     {
    //       id: 999,
    //       name: "class 999",
    //       des: "description for 999",
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );

    //   console.log(response);
    // } catch (error) {
    //   alert(error);
    // }
  };

  const postUser = async () => {
    try {
      const response = await axios.post(
        "https://northwind-worker.lab1.workers.dev/api/customer"
      );

      alert(JSON.stringify(response));
    } catch (error) {
      alert(error);
    }
  };
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <button onClick={postData}>POST CLASS</button>
        <br />
        <button onClick={postUser}>POST USER</button>

        <p>CF Wrangler</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
